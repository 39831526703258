import React from "react"
import CategoryCard from "components/categoryCard"
import Layout from "components/layout"
import { graphql } from "gatsby"

const category = "cpu";
const path = "/category/cpu/";
export default function Category(props) {
  // collect images of pcparts category
  const pages = props.data.site.siteMetadata.pages.filter(page => page.category === category);
  pages.forEach(page => {
    page.image = page.image || "cover/defaultthumb.png"
    const name = page.image.split('.')[0].replace(/-/g,'');
    page.name = name.substring(name.lastIndexOf('/') + 1);
  });
  return (
    <Layout {...props} path={path}>
      <h1>CPU性能比較の記事一覧</h1>
      {pages.map(page => <CategoryCard {...props} {...page}/>)}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        pages {
          title
          description
          image
          category
          path
          noIndex
        }
      }
    }
    cpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    ryzen: file(relativePath: { eq: "cover/cpu/ryzen.png"}) { ...eyecatchImg },
    corei: file(relativePath: { eq: "cover/cpu/corei.png"}) { ...eyecatchImg },
    corei3: file(relativePath: { eq: "cover/cpu/corei3.png"}) { ...eyecatchImg },
    corei5: file(relativePath: { eq: "cover/cpu/corei5.png"}) { ...eyecatchImg },
    corei7: file(relativePath: { eq: "cover/cpu/corei7.png"}) { ...eyecatchImg },
    corei9: file(relativePath: { eq: "cover/cpu/corei9.png"}) { ...eyecatchImg },
    celeron: file(relativePath: { eq: "cover/cpu/celeron.png"}) { ...eyecatchImg },
    cpunotepc: file(relativePath: { eq: "cover/cpu/cpunotepc.png"}) { ...eyecatchImg },
    cpucospa: file(relativePath: { eq: "cover/cpu/cpucospa.png"}) { ...eyecatchImg },
    cpuextreme: file(relativePath: { eq: "cover/cpu/cpuextreme.png"}) { ...eyecatchImg },
    ryzenintel: file(relativePath: { eq: "cover/cpu/ryzen-intel.png"}) { ...eyecatchImg },
    applecpu: file(relativePath: { eq: "cover/cpu/apple-cpu.png"}) { ...eyecatchImg },
    defaultthumb: file(relativePath: { eq: "cover/defaultthumb.png"}) { ...eyecatchImg },
  },
`
